import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Download, Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Eye, Globe, User } from "react-feather";
import Table from "../../pagination/datatable";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";
import BarcodePrinter from "../inventory/barcodePrinter";
import BatchModal from "./batchModal";
import { GetRequest, PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
// import { showDeleteConfirmationAlert } from "../../../functions/SwalAlerts";
// import BarcodeScanner from "./BarcodeScanner";
// import { BarcodeScanner } from "react-barcode-scanner";
// import "react-barcode-scanner/polyfill";
// import QRCodeScannerComponent from "./BarcodeScanner";

const SBatch = () => {
  const [data, setData] = useState([]);
  const [Products, setProducts] = useState([]);
  const [barSelect, setBarSelect] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  // const handleScan = (data) => {
  //   // alert(`Scanned Data: ${data}`);
  //   console.log(data);
  //   // Handle scanned GS1 DataMatrix data
  // };

  const fetchdata = async () => {
    const res = await GetRequest(ApiUrl.batch);
    const proRes = await GetRequest(ApiUrl.product);

    console.log(res, "res");
    if (res) {
      setData(res);
    }
    if (proRes) {
      setProducts(proRes);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const Tatmeem = async () => {
    const res = await PostRequest(ApiUrl.tatmeem, {
      type: "COMMISSIONING",
      parent: selectedRowKeys,
    });
    if (res) {
      setSelectedRowKeys([]);
    }
  };

  const PrintBarcode = async (id) => {
    console.log(id);
    const res = await GetRequest(`${ApiUrl.batch}/${id}`);
    if (res) {
      const fileUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/PDF/${res.filePath}`;
      window.open(fileUrl, "_blank");
    }
  };

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Store Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
  ];

  const columns = [
    {
      title: "Batch",
      dataIndex: "formattedId",
      // render: (data) => `B${data.toString().padStart(5, "0")}`,
      sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (dat) => <p>{dat?.name}</p>,
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry",
      render: (d) => new Date(d).toLocaleString(),
      sorter: (a, b) => a.Customer.length - b.Customer.length,
    },

    {
      title: "Manufacture Date",
      dataIndex: "manufacture",
      render: (d) => new Date(d).toLocaleString(),
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Batch Total Items",
      dataIndex: "totalItems",
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },

    {
      title: "Permit (Shipment)",
      dataIndex: "permit",
      sorter: (a, b) => a.Country.length - b.Country.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {record?.batchItems?.length > 0 && (
              <Link
                className="me-2 p-2"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#prints-barcode"
                onClick={() => setBarSelect(record?.batchItems)}
              >
                <Eye className="feather-view" />
              </Link>
            )}
            {record?.batchItems?.length > 0 && (
              <Link
                className="me-2 p-2"
                onClick={() => PrintBarcode(record?.id)}
              >
                <Download className="feather-view" />
              </Link>
            )}

            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={showDeleteConfirmationAlert}
            >
              <Trash2 className="feather-trash-2" />
            </Link> */}
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Batch List"
          subtitle="Manage Your Batch"
          addButton="Add Batch"
          tatmeemButtonAction={selectedRowKeys.length > 0 && Tatmeem}
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  classNamePrefix="react-select"
                  className="img-select"
                  options={options}
                  placeholder="Sort by Date"
                />
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        classNamePrefix="react-select"
                        className="img-select"
                        options={optionsTwo}
                        placeholder="Choose Store Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Globe className="info-img" />
                      <Select
                        classNamePrefix="react-select"
                        className="img-select"
                        options={countries}
                        placeholder="Choose Country"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <BatchModal
        Products={Products.map((it) => ({ value: it.id, label: it.name }))}
        OnFinish={fetchdata}
      />
      <BarcodePrinter data={barSelect} />
    </div>
  );
};

export default SBatch;
