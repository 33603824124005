import React, { useState, useRef, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";
import PropTypes from "prop-types";

const QRCodeScannerComponent = ({ onScanned }) => {
  const videoRef = useRef(null);
  const [qrData, setQrData] = useState(null);
  const [error, setError] = useState(null);
  // const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    let codeReader;
    const startScanner = async () => {
      try {
        // Request camera access
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "environment" },
        });
        videoRef.current.srcObject = stream;

        // Play video once metadata is loaded
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };

        // setHasPermission(true);

        // Initialize the code reader
        codeReader = new BrowserMultiFormatReader();
        codeReader.decodeFromVideoElement(
          videoRef.current,
          (result, decodeError) => {
            if (result) {
              onScanned(result);

              setQrData(result.text);
              console.log("Scanned Data:", result.text);
            }
            if (decodeError) {
              // console.error("Decode error:", decodeError.message);
            }
          }
        );
      } catch (err) {
        console.error("Camera permission denied:", err);
        setError("Please grant camera access to scan QR codes.");
      }
    };

    startScanner();

    // Cleanup function to stop the video stream
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
      if (codeReader) {
        codeReader = null; // Clean up the codeReader instance
      }
    };
  }, []);

  return (
    <div>
      <h1>GS1 DataMatrix Scanner</h1>
      {error && <p>{error}</p>}
      <video
        ref={videoRef}
        style={{ width: "100%", height: "auto" }}
        autoPlay
        muted
        playsInline
      />
      {qrData && (
        <div>
          <p>Scanned Data:</p>
          <pre>{qrData}</pre>
        </div>
      )}
    </div>
  );
};

QRCodeScannerComponent.propTypes = {
  onScanned: PropTypes.func,
};

export default QRCodeScannerComponent;
